import React, { Fragment } from "react";
import Footer from "../../containers/footer";
import TheHeader from "../../containers/TheHeader";

const Internship = () => {
  return (
    <Fragment>
      <TheHeader />

      <main
        className="js-animsition-overlay"
        data-animsition-overlay="true"
        data-target="dark"
      >
        {/* page head start */}
        <section
          id="up"
          className="pos-rel section-bg-light-2"
          data-target="dark"
        >
          {/* pos-rel start */}
          <div className="pos-rel text-color-black flex-min-height-14vh">
            <div className="container padding-top-bottom-120 after-preloader-anim">
              <h3 className="headline-xxxs hidden-box">
                <span className="anim-slide">Internships</span>
              </h3>
              <h2 className="subhead-xxl margin-top-20 anim-text-reveal tr-delay-03">
                At Zero Gravity, we are all about learning and growing and as
                part of our mission to grow the next generation of animation and
                media talent in Africa, we are always on the lookout for
                talented and driven individuals to join our team as Interns.
                This position is available for animators, illustrators,
                designers and social media interns. You’ll get to work alongside
                some of the most talented thinkers and makers, creating exciting
                projects for some amazing brands. It's exciting and sometimes
                fast-paced and no two days are exactly the same but you can rest
                assured that you'll learn a tonne and have fun with our growing
                family.
              </h2>
            </div>
          </div>
          {/* pos-rel end */}
        </section>
        {/* page head end */}
        {/* services start */}
        <section
          id="down"
          className="pos-rel section-bg-light-2"
          data-target="dark"
        >
          {/* pos-rel start */}
          <div className="pos-rel flex-min-height-100vh">
            {/* width-100perc start */}
            <div className="width-100perc padding-bottom-120">
              {/* padding-top-90 start */}

              {/* padding-top-90 end */}
              {/* padding-top-90 start */}
              <div className="padding-top-120 text-color-black">
                <h2 className="headline-l container hidden-box">
                  <span className="anim-slide js-scrollanim">Internship</span>
                </h2>
                {/* accordion start */}
                <div className="accordion accordion_underline js-accordion margin-top-60">
                  {/* accordion__tab start */}
                  <div className="accordion__tab js-accordion-tab">
                    <div className="accordion__btn js-accordion-btn container js-pointer-large">
                      <h6 className="accordion__btn-title headline-xs margin-top-bottom-30">
                        What can I expect if I become a Go! Intern?
                      </h6>
                    </div>
                    <div className="accordion__content js-accordion-content container">
                      <p className="body-text-l">Coming Soon</p>
                    </div>
                  </div>
                  {/* accordion__tab end */}
                  {/* accordion__tab start */}
                  <div className="accordion__tab js-accordion-tab">
                    <div className="accordion__btn js-accordion-btn container js-pointer-large">
                      <h6 className="accordion__btn-title headline-xs margin-top-bottom-30">
                        When do you run Go! Internships?
                      </h6>
                    </div>
                    <div className="accordion__content js-accordion-content container">
                      <p className="body-text-l">Coming Soon</p>
                    </div>
                  </div>

                  {/* accordion__tab end */}
                  {/* accordion__tab start */}
                  <div className="accordion__tab js-accordion-tab">
                    <div className="accordion__btn js-accordion-btn container js-pointer-large">
                      <h6 className="accordion__btn-title headline-xs margin-top-bottom-30">
                        How do I apply?
                      </h6>
                    </div>
                    <div className="accordion__content js-accordion-content container">
                      <p className="body-text-l">Coming Soon</p>
                    </div>
                  </div>

                  <div className="accordion__tab js-accordion-tab">
                    <div className="accordion__btn js-accordion-btn container js-pointer-large">
                      <h6 className="accordion__btn-title headline-xs margin-top-bottom-30">
                        Do you have a Work Experience program?
                      </h6>
                    </div>
                    <div className="accordion__content js-accordion-content container">
                      <p className="body-text-l">Coming Soon</p>
                    </div>
                  </div>
                  {/* accordion__tab end */}
                </div>
                {/* accordion end */}
              </div>
              {/* padding-top-90 end */}
              {/* padding-top-90 start */}

              {/* accordion__tab end */}

              {/* padding-top-90 end */}
            </div>
            {/* width-100perc end */}
          </div>
          {/* pos-rel end */}
        </section>
        {/* services end */}

        {/* form section start */}
        <section
          className="pos-rel bg-img-cover"
          // style={{backgroundImage: 'url(assets/images/backgrounds/architecture-buildings-city-fog-373965.jpg)'}}
        >
          {/* bg-overlay */}
          <div className="bg-overlay-black" />
          {/* pos-rel start */}
          <div className="pos-rel flex-min-height-100vh">
            {/* container start */}
            <div className="container small padding-top-bottom-120 form-box">
              <h4 className="headline-xxxxs text-center">Lets Get In Touch!</h4>
              {/* flex-container start */}
              <form
                action
                method="post"
                name="formobrsv"
                id="send_form"
                className="flex-container padding-top-90"
              >
                {/* column start */}
                <div className="four-columns">
                  <div className="column-r-margin-10">
                    <input
                      type="text"
                      name="first_name"
                      placeholder="Enter your name"
                      id="first_name"
                      required
                      className="form-input js-pointer-small"
                    />
                  </div>
                </div>
                {/* column end */}
                {/* column start */}
                <div className="four-columns">
                  <div className="column-l-r-margin-5">
                    <input
                      type="text"
                      name="last_name"
                      placeholder="Enter your last name"
                      id="last_name"
                      required
                      className="form-input js-pointer-small"
                    />
                  </div>
                </div>
                {/* column end */}
                {/* column start */}
                <div className="four-columns">
                  <div className="column-l-margin-10">
                    <input
                      type="email"
                      name="email"
                      placeholder="Enter your email address"
                      id="email"
                      required
                      className="form-input js-pointer-small"
                    />
                  </div>
                </div>
                {/* column end */}
                {/* column start */}
                <div className="twelve-columns">
                  <textarea
                    name="message"
                    placeholder="Enter your message"
                    id="message"
                    required
                    className="form-input js-pointer-small"
                    defaultValue={""}
                  />
                </div>
                {/* column end */}
                {/* column start */}
                <div className="twelve-columns text-center padding-top-90">
                  <button id="send" className="border-btn js-pointer-large">
                    <span className="border-btn__inner">submit</span>
                    <span className="btn-wait">Wait</span>
                    <span className="border-btn__lines-1" />
                    <span className="border-btn__lines-2" />
                  </button>
                </div>
                {/* column end */}
              </form>
              {/* flex-container end */}
            </div>
            {/* container end */}
            {/* alert start */}
            <div className="js-popup-fade" id="m_sent">
              <div className="js-popup">
                <div className="popup-icon">
                  <i className="fas fa-check" />
                </div>
                <p className="popup-alert headline-xs">
                  Thank you!
                  <br />
                  Your submission
                  <br />
                  has been received!
                </p>
                <div className="js-popup-close js-pointer-large">
                  <div className="flip-btn" data-text="Close">
                    Close
                  </div>
                </div>
              </div>
            </div>
            {/* alert end */}
            {/* alert start */}
            <div className="js-popup-fade" id="m_err">
              <div className="js-popup">
                <div className="popup-icon">
                  <i className="fas fa-times" />
                </div>
                <p className="popup-alert headline-xs">Error</p>
                <div className="js-popup-close js-pointer-large">
                  <div className="flip-btn" data-text="Close">
                    Close
                  </div>
                </div>
              </div>
            </div>
            {/* alert end */}
          </div>
          {/* pos-rel end */}
        </section>
      </main>
      <Footer />
    </Fragment>
  );
};

export default Internship;
