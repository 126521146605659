import React, { Fragment } from "react";
import Footer from "../containers/footer";
import TheHeader from "../containers/TheHeader";
import { constants } from "../tools";
// import ContactJumbotron from "./components/contact-jumbotron";

const Contact = () => {
  return (
    <Fragment>
      {/* scroll down btn end */}
      <TheHeader />
      {/* main start */}
      <main className="js-animsition-overlay" data-animsition-overlay="true">
        {/* pos-rel start */}
        <section
          id="up"
          className="pos-rel js-parallax-bg"
          style={{
            backgroundImage: "url(avatar/kore/kore_new.png)",
            // "url(assets/new/all_zg.jpg)",
          }}
        >
          {/* bg-overlay */}
          <div className="bg-overlay-black" />
          {/* pos-rel start */}
          <div className="pos-rel flex-min-height-100vh">
            <div className="padding-top-bottom-120 container small after-preloader-anim">
              {/* title start */}
              <h2 className="headline-xl">
                <span className="hidden-box d-block">
                  <span className="anim-slide">Let's bring</span>
                </span>
                <span className="hidden-box d-block">
                  <span className="anim-slide tr-delay-01">your story</span>
                </span>
                <span className="hidden-box d-block">
                  <span className="anim-slide tr-delay-02">to life</span>
                </span>
              </h2>
              {/* title end */}
              <p className="subhead-xs text-color-dadada margin-top-20 anim-fade tr-delay-04">
                Ready to make something amazing with us?
              </p>
            </div>
          </div>
          {/* pos-rel end */}
        </section>
        {/* pos-rel end */}
        {/* <div
          style={{ height: "100vh", backgroundColor: "white" }}
          class="container"
        >
          <div class="inner-container">
            <div class="shape"></div>
          </div>
        </div> */}
        {/* contact us start */}
        <section
          id="down"
          className="pos-rel section-bg-light-1"
          // data-midnight="black"
        >
          {/* pos-rel start */}
          <div className="pos-rel flex-min-height-100vh">
            {/* container start */}
            <div className="padding-top-bottom-120  container">
              {/* content start */}
              <div className="js-scrollanim">
                <h2 className="headline-l cus">
                  <span className="anim-text-double-fill " data-text="Contact">
                    Contact
                  </span>
                  <span
                    className="anim-text-double-fill tr-delay-02"
                    data-text="Us"
                  >
                    Us
                  </span>
                </h2>
                <p className="body-text-s margin-top-20 anim-text-reveal tr-delay-04"></p>
              </div>
              {/* content end */}
              {/* flex-container start */}
              <div className="flex-container padding-top-30">
                {/* column start */}
                <div className="four-columns column-50-100 padding-top-60">
                  <div className="column-r-margin-20 js-scrollanim">
                    <p
                      className="headline-xxxxs anim-text-double-fill "
                      data-text="Email us"
                    >
                      Email us
                    </p>
                    <div className="padding-top-20">
                      <h6 className="anim-fade tr-delay-01">
                        <a
                          href="#"
                          className="subhead-xxs text-color-6d6d6d  text-hover-to-red js-pointer-small"
                        >
                          {constants?.email ?? ""}
                        </a>
                      </h6>
                    </div>
                  </div>
                </div>
                {/* column end */}
                {/* column start */}
                <div className="four-columns column-50-100 padding-top-60">
                  <div className="column-r-margin-20 js-scrollanim">
                    <p
                      className="headline-xxxxs anim-text-double-fill "
                      data-text="Visit us"
                    >
                      Visit us
                    </p>
                    <h6 className="list list_margin-1px padding-top-20 text-color-6d6d6d">
                      <span className="list__item">
                        <span className="d-inline-block hidden-box">
                          <span className="subhead-xxs anim-reveal tr-delay-01">
                            {constants?.address}
                          </span>
                        </span>
                      </span>
                    </h6>
                  </div>
                </div>
                {/* column end */}
                {/* column start */}
                <div className="four-columns column-50-100 padding-top-60">
                  <div className="column-r-margin-20 js-scrollanim">
                    <p
                      className="headline-xxxxs anim-text-double-fill "
                      data-text="Call us"
                    >
                      Call us
                    </p>
                    <div className="padding-top-20">
                      <h6 className="anim-fade tr-delay-01">
                        <a
                          href="#"
                          className="subhead-xxs text-color-6d6d6d  text-hover-to-red js-pointer-small"
                        >
                          {constants?.phoneNumber}
                        </a>
                      </h6>
                    </div>
                  </div>
                </div>
                {/* column end */}
              </div>
              {/* flex-container end */}
              {/* social btns start */}
              <div className="padding-top-90">
                <ul className="list list_row list_margin-30px js-scrollanim">
                  <li className="list__item anim-fade">
                    <a
                      target="_blank"
                      href="https://www.facebook.com/ZeroGravityAfrica"
                      className="fill-btn text-color-979797 js-pointer-small"
                      data-text="Facebook"
                    >
                      Facebook
                    </a>
                  </li>

                  <li className="list__item anim-fade tr-delay-02">
                    <a
                      target="_blank"
                      href="https://www.instagram.com/zerogravitystudios/"
                      className="fill-btn text-color-979797 js-pointer-small"
                      data-text="Instagram"
                    >
                      Instagram
                    </a>
                  </li>
                  <li className="list__item anim-fade tr-delay-03">
                    <a
                      target="_blank"
                      href="https://vimeo.com/user67180687"
                      className="fill-btn text-color-979797 js-pointer-small"
                      data-text="Vimeo"
                    >
                      Vimeo
                    </a>
                  </li>
                  <li className="list__item anim-fade tr-delay-04">
                    <a
                      target="_blank"
                      href="https://www.behance.net/ZeroGravityAfrica"
                      className="fill-btn text-color-979797 js-pointer-small"
                      data-text="Behance"
                    >
                      Behance
                    </a>
                  </li>
                </ul>
              </div>
              {/* social btns end */}
            </div>
            {/* container end */}
          </div>
          {/* pos-rel end */}
        </section>

        {/* form section end */}
      </main>
      {/* main end */}
      {/* footer start */}
      <Footer />
      {/* footer end */}
    </Fragment>
  );
};

export default Contact;
