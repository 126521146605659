import React, { Fragment, useEffect, useState } from "react";
import Footer from "../containers/footer";
import TheHeader from "../containers/TheHeader";
import Loader from "./components/loader";

const Home = () => {
  return (
    <Fragment>
      {/* header start */}
      <TheHeader />
      {/* main start */}
      <main className="js-animsition-overlay" data-animsition-overlay="true">
        {/* home slider start */}
        <section style={{ position: "relative" }}>
          <video
            className="home-header"
            autoPlay
            muted
            loop
            // id="myVideo"
          >
            <source src="vids/reels.mp4" type="video/mp4" />
          </video>
          <div className="foreground-text text-left">
            <div className="pos-rel flex-min-height-100vh">
              <div className="padding-top-bottom-120 container small after-preloader-anim">
                {/* title start */}
                <h2 className="headline-xl">
                  <span className="hidden-box d-block">
                    <span className="anim-slide">Stories</span>
                  </span>
                  <span className="hidden-box d-block">
                    <span className="anim-slide tr-delay-01">That Touch</span>
                  </span>

                  <p className="subhead-xs text-color-dadada anim-fade tr-delay-04">
                    We exist to tell stories that touch lives and create
                    positive social impact
                  </p>
                </h2>
                {/* title end */}
              </div>
            </div>
          </div>
        </section>

        <section id="down" className="pos-rel custom-bg-pink">
          {/* pos-rel start */}
          <div className="pos-rel padding-top-bottom-120">
            {/* flex-container start */}
            <div className="container flex-container padding-bottom-70">
              {/* column start */}
              <div className="six-columns padding-bottom-20">
                {/* title start */}
                <h2 className="column-l-r-margin-10 headline-m js-scrollanim">
                  <span className="hidden-box d-block">
                    <span className="anim-slide">
                      We Are{" "}
                      <span className="custom-yellow">Award-Winning</span>
                    </span>
                  </span>
                  <span className="hidden-box d-block">
                    <span className="anim-slide tr-delay-01 custom-yellow">
                      Creative People
                    </span>
                  </span>
                </h2>
                {/* title end */}
                <div className="d-flex">
                  <div style={{ width: 300, marginTop: 36 }}>
                    <img
                      src="assets/new/winner2.png"
                      alt="winner"
                      height="auto"
                      width="240px"
                    />
                  </div>
                </div>
              </div>
              {/* column end */}
              {/* column start */}
              <div className="six-columns padding-bottom-20">
                <div className="column-l-r-margin-10">
                  <h3 className="headline-xxs">
                    Our aim is to enrich your brand by creating simple yet
                    extraordinary solutions that put YOU centre stage.
                  </h3>
                  <br />
                  <p className="body-text-l anim-text-reveal js-scrollanim">
                    We seek to gain a good understanding of our clients needs so
                    that we can tailor solutions to these exact needs, and
                    deliver high-value results that transform their businesses
                    and help them WIN.
                  </p>
                  {/* list start */}
                  <ul className="list text-color-dadada margin-top-30 js-scrollanim">
                    <li className="list__item yellow dot hidden-box">
                      <div className="subhead-m anim-slide">Animation</div>
                    </li>
                    <li className="list__item yellow dot hidden-box">
                      <div className="subhead-m anim-slide tr-delay-01">
                        Film
                      </div>
                    </li>
                    <li className="list__item yellow dot hidden-box">
                      <div className="subhead-m anim-slide tr-delay-02">
                        Copywriting
                      </div>
                    </li>
                    <li className="list__item yellow dot hidden-box">
                      <div className="subhead-m anim-slide tr-delay-03">
                        Social Media Marketing
                      </div>
                    </li>
                    <li className="list__item yellow dot hidden-box">
                      <div className="subhead-m anim-slide tr-delay-04">
                        Content and Digital Strategy
                      </div>
                    </li>
                    <li className="list__item yellow dot hidden-box">
                      <div className="subhead-m anim-slide tr-delay-05">
                        Brand and Content Development.
                      </div>
                    </li>
                    {/* <li className="list__item red dot hidden-box">
                      <div className="subhead-xxs anim-slide tr-delay-06">
                        Back-end Development
                      </div>
                    </li> */}
                  </ul>
                  {/* list end */}
                </div>
              </div>
              {/* column end */}
            </div>
            {/* flex-container end */}
            {/* js-infinite-slider start */}
            <div className="js-infinite-slider about-slider hidden-box">
              {/* swiper-wrapper start */}
              <div className="swiper-wrapper">
                {/* swiper-slide start */}
                <div className="swiper-slide">
                  <img src="assets/new/slide/1.jpg" alt="About us" />
                </div>
                {/* swiper-slide end */}
                {/* swiper-slide start */}
                <div className="swiper-slide">
                  <img src="assets/new/slide/2.jpg" alt="About us" />
                  <img
                    className="padding-top-20"
                    src="assets/new/slide/3.jpg"
                    alt="About us"
                  />
                </div>
                {/* swiper-slide end */}
                {/* swiper-slide start */}
                <div className="swiper-slide">
                  <img src="assets/new/slide/4.jpg" alt="About us" />
                </div>
                {/* swiper-slide end */}
                {/* swiper-slide start */}
                <div className="swiper-slide">
                  <img src="assets/new/slide/6.jpg" alt="About us" />
                </div>
                {/* swiper-slide end */}
                {/* swiper-slide start */}
                <div className="swiper-slide">
                  <img src="assets/new/slide/5.jpg" alt="About us" />
                  <img
                    className="padding-top-20"
                    src="assets/new/slide/7.jpg"
                    alt="About us"
                  />
                </div>
                {/* swiper-slide end */}
                {/* swiper-slide start */}
                <div className="swiper-slide">
                  <img src="assets/new/slide/8.jpg" alt="About us" />
                </div>
                {/* swiper-slide end */}
                {/* swiper-slide start */}
                <div className="swiper-slide">
                  <img src="assets/new/slide/9.jpg" alt="About us" />
                </div>
                {/* swiper-slide end */}
                {/* swiper-slide start */}
                <div className="swiper-slide">
                  <img src="assets/new/slide/11.jpg" alt="About us" />
                  <img
                    className="padding-top-20"
                    src="assets/new/slide/12.jpg"
                    alt="About us"
                  />
                </div>
                {/* swiper-slide end */}
                {/* swiper-slide start */}
                <div className="swiper-slide">
                  <img src="assets/new/slide/10.jpg" alt="About us" />
                </div>
                {/* swiper-slide end */}
              </div>
              {/* swiper-wrapper end */}
            </div>
            {/* js-infinite-slider end */}
          </div>
          {/* pos-rel end */}
        </section>
        {/* about us end */}
        {/* portfolio start */}

        {/* latest news start */}
        <section className="pos-rel section-bg-light-1" data-midnight="black">
          {/* pos-rel start */}
          <div className="pos-rel flex-min-height-100vh">
            {/* container start */}
            <div className="padding-top-bottom-120 container">
              {/* title start */}
              <h2 className="headline-xl text-color-black text-center hidden-box">
                <span className="anim-slide js-scrollanim">
                  From Our <span className="custom-purple">Originals</span>
                </span>
              </h2>
              {/* title end */}
              {/* flex-container start */}
              <div className="flex-container padding-top-60">
                {/* blog-entry start */}
                <article className="four-columns column-100-100 padding-top-30 js-scrollanim">
                  <div className="column-l-r-margin-10-999 hover-box pos-rel">
                    <a
                      href="post_dont-panic.html"
                      className="d-block hidden-box js-pointer-large js-animsition-link"
                    >
                      <img
                        className="img-hover-scale"
                        src="avatar/amina/1.png"
                        alt="Post"
                        height="260px"
                      />
                      {/* bg-overlay */}
                      <div className="bg-overlay-black" />
                      <h3 className="headline-xxxs pos-abs pos-left-bottom list list_margin-1px hover-move-right">
                        <span className="list__item">
                          <span className="hidden-box d-inline-block">
                            <span className="anim-reveal">Aminah's</span>
                          </span>
                        </span>
                        <span className="list__item">
                          <span className="hidden-box d-inline-block">
                            <span className="anim-reveal tr-delay-01">
                              Journey
                            </span>
                          </span>
                        </span>
                      </h3>
                    </a>
                    {/* <div className="pos-abs pos-left-top d-flex flex-align-center">
                      <img
                        className="border-radius-50perc margin-right-10 author-avatar anim-fade"
                        src="assets/images/avatars/author/man-in-red-crew-neck-sweatshirt-photography-941693.jpg"
                        alt="Author"
                      />
                      <div className="hidden-box">
                        <a
                          href="#"
                          className="subhead-xxs anim-slide js-pointer-small"
                        >
                          By: Jeffery Reder
                        </a>
                      </div>
                    </div> */}
                  </div>
                </article>
                {/* blog-entry end */}
                {/* blog-entry start */}
                <article className="four-columns column-100-100 padding-top-30 js-scrollanim">
                  <div className="column-l-r-margin-10-999 hover-box pos-rel">
                    <a
                      href="post_dont-panic.html"
                      className="d-block hidden-box js-pointer-large js-animsition-link"
                    >
                      <img
                        className="img-hover-scale"
                        src="avatar/Kore/kore_new.png"
                        alt="Post"
                        height="260px"
                      />
                      {/* bg-overlay */}
                      <div className="bg-overlay-black" />
                      <h3 className="headline-xxxs pos-abs pos-left-bottom list list_margin-1px hover-move-right">
                        <span className="list__item">
                          <span className="hidden-box d-inline-block">
                            <span className="anim-reveal">Kore</span>
                          </span>
                        </span>
                        <span className="list__item">
                          <span className="hidden-box d-inline-block">
                            <span className="anim-reveal tr-delay-01">
                              The power of afrobeat
                            </span>
                          </span>
                        </span>
                      </h3>
                    </a>
                  </div>
                </article>
                {/* blog-entry end */}
                {/* blog-entry start */}

                <article className="four-columns column-100-100 padding-top-30 js-scrollanim">
                  <div className="column-l-r-margin-10-999 hover-box pos-rel">
                    <a
                      href="post_dont-panic.html"
                      className="d-block hidden-box js-pointer-large js-animsition-link"
                    >
                      <img
                        className="img-hover-scale"
                        // src="assets/images/posts/insomnia/photo-of-food-in-a-bowl-3296546.jpg"
                        src="avatar/kweeto/1.png"
                        alt="Post"
                        height="260px"
                      />
                      {/* bg-overlay */}
                      <div className="bg-overlay-black" />
                      <h3 className="headline-xxxs pos-abs pos-left-bottom list list_margin-1px hover-move-right">
                        <span className="list__item">
                          <span className="hidden-box d-inline-block">
                            <span className="anim-reveal">Kweeto</span>
                          </span>
                        </span>
                        <span className="list__item">
                          <span className="hidden-box d-inline-block">
                            <span className="anim-reveal tr-delay-01">
                              The genius mosquito
                            </span>
                          </span>
                        </span>
                      </h3>
                    </a>
                    {/* <div className="pos-abs pos-left-top d-flex flex-align-center">
                      <img
                        className="border-radius-50perc margin-right-10 author-avatar anim-fade"
                        src="assets/images/avatars/author/person-in-teal-ice-cream-print-tank-top-holds-ice-cream-886285.jpg"
                        alt="Author"
                      />
                      <div className="hidden-box">
                        <a
                          href="#"
                          className="subhead-xxs anim-slide js-pointer-small"
                        >
                          By: Nick Hufnagel
                        </a>
                      </div>
                    </div> */}
                  </div>
                </article>
                {/* blog-entry end */}
              </div>
              {/* flex-container end */}
            </div>
            {/* container end */}
          </div>
          {/* pos-rel end */}
        </section>
        {/* latest news end */}

        {/* clients start */}
        <section className="pos-rel bg-white" data-midnight="black">
          {/* pos-rel start */}
          <div className="pos-rel flex-min-height-100vh">
            {/* container start */}
            <div className="padding-top-bottom-120 container">
              {/* title start */}
              <div className="row">
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <h2 className="headline-l js-scrollanim">
                    <span className="hidden-box d-block">
                      <span className="anim-slide">
                        <span className="custom-pink">Fine Folks</span>
                      </span>
                    </span>
                    <span className="hidden-box d-block custom-purple">
                      <span className="anim-slide tr-delay-01">
                        We’ve Worked
                      </span>
                    </span>
                    <span className="hidden-box d-block custom-purple">
                      <span className="anim-slide tr-delay-02">With</span>
                    </span>
                  </h2>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="d-flex justify-content-center">
                    <img
                      className="new-img"
                      // src="avatar/zgp.png"
                      // style={{ height: "120px", width: "100px" }}
                      src="avatar/zg-logo.svg"
                      height="auto"
                      width="100px"
                      alt="logo"
                    />
                  </div>
                </div>
              </div>

              {/* title end */}
              {/* clients-lines start */}
              <div className="clients-lines padding-top-90">
                {/* clients-lines__column start */}
                <div className="clients-lines__column">
                  <div className="clients-lines__inner grey-scaly hover-box pos-rel">
                    <img
                      className="client-logo  pos-abs pos-center-center"
                      src="avatar/logo/GTB.png"
                      alt="clients logo"
                    />
                  </div>
                </div>
                {/* clients-lines__column end */}
                {/* clients-lines__column start */}
                <div className="clients-lines__column">
                  <div className="clients-lines__inner grey-scaly hover-box pos-rel">
                    <img
                      className="client-logo  pos-abs pos-center-center"
                      src="avatar/logo/CACOVID.jpg"
                      alt="clients logo"
                    />
                  </div>
                </div>
                {/* clients-lines__column end */}
                {/* clients-lines__column start */}
                <div className="clients-lines__column">
                  <div className="grey-scaly  clients-lines__inner hover-box pos-rel">
                    <img
                      className="heirs-logo pos-abs pos-center-center"
                      src="avatar/logo/heirs.png"
                      alt="clients logo"
                    />
                  </div>
                </div>
                {/* clients-lines__column end */}
                {/* clients-lines__column start */}
                <div className="clients-lines__column">
                  <div className="grey-scaly  clients-lines__inner hover-box pos-rel">
                    <img
                      className="client-logo   pos-abs pos-center-center"
                      src="avatar/logo/KPMG.png"
                      alt="clients logo"
                    />
                  </div>
                </div>
                {/* clients-lines__column end */}
                {/* clients-lines__column start */}
                <div className="clients-lines__column">
                  <div className="grey-scaly  clients-lines__inner hover-box pos-rel">
                    <img
                      className=" pos-abs pepsi-icon pos-center-center"
                      src="avatar/logo/pepsi.png"
                      alt="clients logo"
                    />
                  </div>
                </div>
                {/* clients-lines__column end */}
                {/* clients-lines__column start */}
                <div className="clients-lines__column">
                  <div className="grey-scaly  clients-lines__inner hover-box pos-rel">
                    <img
                      className="client-logo   pos-abs pos-center-center"
                      src="avatar/logo/sfh.png"
                      alt="clients logo"
                    />
                  </div>
                </div>
                {/* clients-lines__column end */}
                {/* clients-lines__column start */}
                <div className="clients-lines__column">
                  <div className="grey-scaly  clients-lines__inner hover-box pos-rel">
                    <img
                      className="silver-bird-logo pos-abs pos-center-center"
                      src="avatar/logo/silverbird.png"
                      height="auto"
                      width="auto"
                      alt="clients logo"
                    />
                  </div>
                </div>
                {/* clients-lines__column end */}
                {/* clients-lines__column start */}
                <div className="clients-lines__column">
                  <div className="grey-scaly  clients-lines__inner hover-box pos-rel">
                    <img
                      className="client-logo  pos-abs pos-center-center"
                      src="avatar/logo/upbeat.png"
                      alt="clients logo"
                    />
                  </div>
                </div>
                {/* clients-lines__column end */}
                {/* clients-lines__column start */}
                <div className="clients-lines__column">
                  <div className="grey-scaly  clients-lines__inner hover-box pos-rel">
                    <img
                      className="client-logo   pos-abs pos-center-center"
                      src="avatar/logo/zenit.png"
                      alt="clients logo"
                    />
                  </div>
                </div>
                <div className="clients-lines__column">
                  <div className="grey-scaly  clients-lines__inner hover-box pos-rel">
                    <img
                      className="client-logo   pos-abs pos-center-center"
                      src="avatar/logo/lafarge.png"
                      alt="clients logo"
                    />
                  </div>
                </div>{" "}
                <div className="clients-lines__column">
                  <div className="grey-scaly  clients-lines__inner hover-box pos-rel">
                    <img
                      className="client-logo   pos-abs pos-center-center"
                      src="avatar/logo/maxivity.jpeg"
                      alt="clients logo"
                    />
                  </div>
                </div>
                <div className="clients-lines__column">
                  <div className="grey-scaly  clients-lines__inner hover-box pos-rel">
                    <img
                      className="client-logo   pos-abs pos-center-center"
                      src="avatar/logo/union_bank.png"
                      alt="clients logo"
                    />
                  </div>
                </div>
                <a href="#" className="clients-lines__column js-pointer-large">
                  <div className="clients-lines__inner hover-box pos-rel  "></div>
                </a>
                {/* clients-lines__column end */}
              </div>
              {/* clients-lines end */}
            </div>
            {/* container end */}
          </div>
          {/* pos-rel end */}
        </section>
        {/* clients end */}
        {/* testimonials start */}
        <section className="pos-rel section-bg-light-1" data-midnight="black">
          {/* pos-rel start */}
          <div className="pos-rel flex-min-height-100vh">
            {/* padding-top-bottom-120 start */}
            <div className="padding-top-bottom-120 width-100perc">
              {/* title start */}
              <h2 className="headline-xxl text-color-black text-center hidden-box">
                <span className="anim-slide js-scrollanim">Testimonials</span>
              </h2>
              {/* title end */}
              {/* js-2-view-slider start */}
              <div className="js-2-view-slider padding-top-bottom-90 hidden-box pos-rel">
                {/* swiper-wrapper start */}
                <div className="swiper-wrapper js-slider-scroll-anim">
                  <div className="swiper-slide slide-scale-anim">
                    <div className="margin-left-right-20 padding-left-right-20 padding-top-bottom-40 content-bg-light-2 border-radius-10px">
                      <p className="margin-bottom-30 quote quote_bottom body-text-m text-color-black text-center">
                        I absolutely loved the stuff you created for us. The
                        quality and clear sense of direction in one word-
                        BRILLIANT.
                      </p>
                      {/* anim-img-scale start */}
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <div
                          className="userIcon"
                          style={{
                            backgroundImage: "url(assets/testimonials/Kemi.png",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                          }}
                        ></div>
                      </div>
                      {/* anim-img-scale end */}
                      <div className="margin-top-20 text-center">
                        <span className="headline-xxxs text-color-black">
                          Kemi O.B
                        </span>
                        <br />
                        <span className="subhead-xxs text-color-888888">
                          CEO, MAXTIVITY CENTRE
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* swiper-slide start */}
                  <div className="swiper-slide slide-scale-anim">
                    <div className="margin-left-right-20 padding-left-right-20 padding-top-bottom-40 content-bg-light-2 border-radius-10px">
                      <p className="margin-bottom-30 quote quote_bottom body-text-m text-color-black text-center">
                        My experience with Zero Gravity was very positive. They
                        were professional, time conscious, resourceful and
                        continually stretched to accommodate all my requests.
                      </p>
                      {/* anim-img-scale start */}
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <div
                          className="userIcon"
                          style={{
                            backgroundImage:
                              "url(assets/testimonials/Osereme.png",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                          }}
                        >
                          {/* <img
                            // className="anim-img-scale__inner"
                            src="assets/testimonials/Kemi.png"
                            alt="author"
                            style={{ color: "#fff" }}
                            width="70px"
                            height="70px"
                          /> */}
                        </div>
                      </div>
                      {/* anim-img-scale end */}
                      <div className="margin-top-20 text-center">
                        <span className="headline-xxxs text-color-black">
                          Osereme O.B
                        </span>
                        <br />
                        <span className="subhead-xxs text-color-888888">
                          IT SERVICE MANAGER, GTBANK PLC
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* swiper-slide end */}
                  {/* swiper-slide start */}
                  <div className="swiper-slide slide-scale-anim">
                    <div className="margin-left-right-20 padding-left-right-20 padding-top-bottom-40 content-bg-light-2 border-radius-10px">
                      <p className="margin-bottom-30 quote quote_bottom body-text-m text-color-black text-center">
                        Working with Zero Gravity has been a refreshing and
                        convenient experience. Their brilliant creative
                        interpretation and professional execution of any brief
                        has always exceeded my expectation.
                      </p>
                      {/* anim-img-scale start */}
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <div
                          className="userIcon"
                          style={{
                            backgroundImage:
                              "url(assets/testimonials/Pascal.png",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                          }}
                        ></div>
                      </div>
                      {/* anim-img-scale end */}
                      <div className="margin-top-20 text-center">
                        <span className="headline-xxxs text-color-black">
                          Pascal O.R
                        </span>
                        <br />
                        <span className="subhead-xxs text-color-888888">
                          HEAD DIGITAL MARKETING, ZENITH BANK PLC
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* swiper-slide end */}
                  {/* swiper-slide start */}

                  {/* swiper-slide end */}
                  {/* swiper-slide start */}

                  {/* swiper-slide end */}
                </div>
                {/* swiper-wrapper end */}
                {/* swiper-button-prev start */}
                <div className="swiper-button-prev-box">
                  <div className="swiper-button-prev black" />
                </div>
                {/* swiper-button-prev end */}
                {/* swiper-button-next start */}
                <div className="swiper-button-next-box">
                  <div className="swiper-button-next black" />
                </div>
                {/* swiper-button-next end */}
                {/* swiper-pagination start */}
                <div className="pagination-box">
                  <div className="swiper-pagination counter-pagination black" />
                </div>
                {/* swiper-pagination end */}
              </div>
              {/* js-2-view-slider end */}
            </div>
            {/* padding-top-bottom-120 end */}
          </div>
          {/* pos-rel end */}
        </section>
        {/* testimonials end */}
        {/* video content start */}
        {/* <div
          className="pos-rel bg-img-cover height-100vh"
          style={{
            backgroundImage:
              "url(assets/images/projects/neon-lights/woman-wearing-beige-and-gray-hoodie-portrait-2272855.jpg)",
          }}
        >
          <div className="bg-overlay-black" />
          <div className="pos-rel height-100perc">
            <a
              href="https://www.youtube.com/watch?v=hitNXU4PoRU"
              className="play-button js-popup-youtube js-pointer-large"
            >
              <span className="play-button__inner" />
            </a>
          </div>
        </div> */}
      </main>
      {/* main end */}
      {/* footer start */}
      <Footer />
      {/* footer end */}
    </Fragment>
  );
};

export default Home;
