import React from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { constants } from "../tools";

const TheHeader = () => {
  return (
    <Fragment>
      <div className="preloader__logoload-box">
        <img
          className="preloader__logo vertical"
          src="assets/new/new-logo.gif"
          height="100%"
          width="100%"
          alt="logo"
        />
        {/* <div className="preloader__pulse" /> */}
      </div>

      <div className="pointer js-pointer" id="js-pointer">
        <i className="pointer__inner fas fa-long-arrow-alt-right" />
        <i className="pointer__inner fas fa-search" />
        <i className="pointer__inner fas fa-link" />
      </div>

      <header className="fixed-header">
        {/* logo start */}
        <div
          style={{ height: 65 }}
          className="header-logo js-midnight-color js-headroom"
        >
          <div className="hidden-box">
            <Link
              to="/"
              className="header-logo__box js-pointer-large js-animsition-link"
            >
              <img
                className="white"
                src="avatar/zg-logo.svg"
                height="60px"
                width="auto"
                alt="logo"
              />
            </Link>
          </div>
        </div>
        {/* logo end */}
        {/* menu-icon start */}
        <div className="menu-icon js-menu-open-close js-pointer-large js-midnight-color js-headroom">
          <div className="menu-icon__box">
            <span className="menu-icon__inner" />
            <span className="menu-icon__close" />
          </div>
        </div>

        <div className="header-social after-preloader-anim js-midnight-color js-headroom">
          <ul className="list list_center list_margin-20px hidden-box">
            <li className="list__item">
              <div className="hidden-box d-inline-block">
                <a
                  target="_blank"
                  href="https://www.facebook.com/ZeroGravityAfrica"
                  className="anim-slide js-pointer-small"
                >
                  <i className="fab fa-facebook-f" />
                </a>
              </div>
            </li>

            <li className="list__item">
              <div className="hidden-box d-inline-block">
                <a
                  target="_blank"
                  href="https://www.instagram.com/zerogravitystudios/"
                  className="anim-slide tr-delay-04 js-pointer-small"
                >
                  <i className="fab fa-instagram" />
                </a>
              </div>
            </li>
            <li className="list__item">
              <div className="hidden-box d-inline-block">
                <a
                  target="_blank"
                  href="https://www.behance.net/ZeroGravityAfrica"
                  className="anim-slide tr-delay-06 js-pointer-small"
                >
                  <i className="fab fa-behance" />
                </a>
              </div>
            </li>
            <li className="list__item">
              <div className="hidden-box d-inline-block">
                <a
                  target="_blank"
                  href="https://vimeo.com/user67180687"
                  className="anim-slide tr-delay-08 js-pointer-small"
                >
                  <i className="fab fa-vimeo" />
                </a>
              </div>
            </li>
          </ul>
        </div>
      </header>
      {/* header end */}
      {/* navigation start */}

      <nav className="nav-container pos-rel js-dropdown-active-box">
        {/* pos-rel start */}
        <div className="pos-rel height-100perc">
          {/* dropdown close btn start */}
          <div className="dropdown-close">
            <div className="dropdown-close__inner js-dropdown-close js-pointer-large">
              <span className="dropdown-close__arrow" />
            </div>
          </div>
          {/* dropdown close btn end */}

          {/* menu-box start */}
          <ul className="menu-box">
            <li className="nav-btn-box">
              <Link
                to="/"
                // href="contact.html"
                className="nav-btn large dropdown-hidden-btn js-animsition-link js-pointer-large"
              >
                <span
                  className="nav-btn__inner hover-purple"
                  //  data-text="Home"
                >
                  Home
                </span>
              </Link>
            </li>
            {/* nav-btn-box start */}

            {/* nav-btn-box end */}
            <li className="nav-btn-box">
              <Link
                to="/projects"
                // href="contact.html"
                className="nav-btn large dropdown-hidden-btn js-animsition-link js-pointer-large"
              >
                <span
                  className="nav-btn__inner hover-yellow"
                  // data-text="Projects"
                >
                  Projects
                </span>
              </Link>
            </li>
            {/* nav-btn-box start */}
            {/* nav-btn-box end */}
            <li className="nav-btn-box">
              <Link
                to="/originals"
                // href="contact.html"
                className="nav-btn large dropdown-hidden-btn js-animsition-link js-pointer-large"
              >
                <span
                  className="nav-btn__inner hover-pink"
                  // data-text="Originals"
                >
                  Originals
                </span>
              </Link>
            </li>
            {/* nav-btn-box start */}
            <li className="nav-btn-box js-dropdown-open">
              <a className="nav-btn large dropdown-hidden-btn js-pointer-large">
                <span
                  className="nav-btn__inner hover-green"
                  // data-text="Careers"
                >
                  Careers
                </span>
              </a>
              {/* dropdown start */}
              <ul className="menu-box dropdown js-dropdown">
                <li className="nav-btn-box">
                  <Link
                    to="/career/jobs"
                    className="nav-btn small js-animsition-link js-pointer-large"
                  >
                    <span className="nav-btn__inner" data-text="Jobs">
                      Jobs
                    </span>
                  </Link>
                </li>
                <li className="nav-btn-box">
                  <Link
                    to="/career/training"
                    className="nav-btn small js-animsition-link js-pointer-large"
                  >
                    <span className="nav-btn__inner" data-text="Training">
                      Training
                    </span>
                  </Link>
                </li>
                <li className="nav-btn-box">
                  <Link
                    to="/career/internship"
                    className="nav-btn small js-animsition-link js-pointer-large"
                  >
                    <span className="nav-btn__inner" data-text="Internship">
                      Internship
                    </span>
                  </Link>
                </li>
              </ul>
              {/* dropdown end */}
            </li>

            <li className="nav-btn-box">
              <Link
                to="/contact"
                className="nav-btn large dropdown-hidden-btn js-animsition-link js-pointer-large"
              >
                <span
                  className="nav-btn__inner hover-pink"
                  // data-text="Contact"
                >
                  Contact
                </span>
              </Link>
            </li>
            <li className="nav-btn-box">
              <Link
                to="/about"
                className="nav-btn large dropdown-hidden-btn js-animsition-link js-pointer-large"
              >
                <span
                  className="nav-btn__inner hover-yellow"
                  // data-text="About"
                >
                  About
                </span>
              </Link>
            </li>
            {/* nav-btn-box end */}
          </ul>
          {/* menu-box end */}
          {/* nav-information start */}
          <div className="nav-information">
            {/* nav-email start */}
            <div>
              <div className="hidden-box d-inline-block">
                <div className="headline-xxxxs nav-title-color nav-reveal-anim js-nav-anim">
                  Email
                </div>
              </div>
              <div className="nav-fade-anim js-nav-anim margin-top-10">
                <a
                  href="#"
                  className="subhead-xxs nav-text-color text-hover-to-red js-pointer-small"
                >
                  {constants?.email}
                </a>
              </div>
            </div>

            <div>
              <div className="hidden-box d-inline-block">
                <div className="headline-xxxxs nav-title-color nav-reveal-anim js-nav-anim">
                  Phone
                </div>
              </div>
              <div className="nav-fade-anim js-nav-anim margin-top-10">
                <a
                  href="#"
                  className="subhead-xxs nav-text-color text-hover-to-red js-pointer-small"
                >
                  {constants.phoneNumber}
                </a>
                <br />
              </div>
            </div>
            {/* nav-phone end */}
          </div>
          {/* nav-information end */}
          {/* nav-copyright start */}
          <div className="nav-copyright text-right">
            <p className="copyright-style nav-fade-anim js-nav-anim">
              © {constants.currentYear} Zero gravity studios.
              <a href="#" className="text-weight-700 js-pointer-small">
                All Rights Reserved.
              </a>
            </p>
          </div>
          {/* nav-copyright end */}
        </div>
        {/* pos-rel end */}
      </nav>
      {/* navigation end */}
    </Fragment>
  );
};

export default TheHeader;
